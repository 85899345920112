
import Swiper, { Navigation, Pagination, EffectFade, Controller } from 'swiper';
import MmenuLight from  '../../node_modules/mmenu-light/src/mmenu-light.js';
import ShareButtons from  '../../node_modules/share-buttons/dist/share-buttons.js';
import MicroModal from 'micromodal';
import MiniMasonry from "minimasonry";


Swiper.use([Navigation, Pagination, EffectFade, Controller]);

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    const sections = document.querySelectorAll('section[id]:not([id="stickyMenu"])')

    sections.forEach(section => {
        section.style.scrollPaddingBottom = '9999px'
    })

    // Mobile menu init // Sync search and menu open

    let mobileMenu = document.querySelector( "#mobile-menu" );
    const projectWrapper = document.getElementById('app');

    // Mobile menu init // Sync search and menu open

    if (mobileMenu){ // validation needed as there are some pages that dont contain top nav menu (custom landing pages)

        const menu = new MmenuLight(mobileMenu,"(max-width: 1024px)" );
        const navigator = menu.navigation();
        const drawer = menu.offcanvas();
        //Open Menu
        const navTrigger = document.querySelector('.js-open-nav');

        if (navTrigger) {
            //Toggle Menu
            bindEvent(navTrigger, "click", function() {
                this.classList.toggle('is-active');
                projectWrapper.classList.toggle('js-is-open');

                console.log('click');

                if (this.classList.contains('is-active')) {
                    drawer.open();
                } else {
                    drawer.close();
                }

            });

        }
    }

    // specs table
    let tables = document.getElementsByClassName('specstable') // in case there is more than 1 table in the dom
    if (tables) {
        tables.forEach(table => {
            let usButton = table.getElementsByClassName('usButton')
            let metricButton = table.getElementsByClassName('metricButton')

            if(usButton.length > 0 && metricButton.length > 0 ){

                usButton[0].addEventListener('click', (event) => {
                    usButton[0].classList.add('active')
                    metricButton[0].classList.remove('active')
                    changeTableInfo(table,'usInfo', 'metricInfo' )
                });

                metricButton[0].addEventListener('click', (event) => {
                    metricButton[0].classList.add('active')
                    usButton[0].classList.remove('active')
                    changeTableInfo(table, 'metricInfo', 'usInfo')
                });
               
            }
        })
    }

    function changeTableInfo (table, show, hide){
        Array.from(table.getElementsByClassName(show)).forEach(function(el) {
            el.classList.remove('hidden');
        });

        Array.from(table.getElementsByClassName(hide)).forEach(function(el) {
            el.classList.add('hidden');
        });
    }
    // ends specs table


    MicroModal.init();

    let modalTriggers = document.querySelectorAll('.modal-trigger')
    let modalGallery = document.getElementById('modal-swap')

    if (modalGallery){
        let cont = modalGallery.getElementsByClassName('modal__content')
        if(cont) {
            modalTriggers.forEach(item => {
                item.addEventListener('click', (event) => {
                    modalGallery.classList.remove('hidden');
                    let datahmtl = item.querySelector('.datahmtl')
                    if (cont[0] && datahmtl){
                        cont[0].innerHTML = ""
                        let copyDatahtml = datahmtl.cloneNode(true)
                        cont[0].appendChild(copyDatahtml).classList.remove('hidden');
                    }
                });
            });
        }

        bindEvent(modalGallery.querySelector('.modal__close'), "click", function() {
            modalGallery.classList.add('hidden');
        });

    }

    const masonryWrapper = document.querySelector('.section-blog-tiles__wrapper');

    if (masonryWrapper) {
        var masonry = new MiniMasonry({
            container: '.section-blog-tiles__wrapper',
            baseWidth: 420,
            gutterX: 0,
            gutterY: 40
        });
    }

    // wrapping tables in divs
    const el = document.querySelectorAll('.wysiwyg-block table')

    el.forEach(item => {  
        const wrapper = document.createElement("div");
        item.parentNode.insertBefore(wrapper, item);
        wrapper.appendChild(item);

        item.parentNode.classList.add('table-wrapper');
    });

    // Mega menu
    var panels = document.querySelectorAll('.panel');
    var panelTriggers = document.querySelectorAll('.js-panel-btn');
    var subPanels = document.querySelectorAll('.sub-panel');
    var subPanelTriggers = document.querySelectorAll('.js-sub-panel-btn');
    var subPanelOverlay = document.querySelector('.navigation-overlay');

    // Mega menu top level
    if (panelTriggers) {
        for (let i = 0, l = panelTriggers.length; i < l; i += 1) {
            panelTriggers[i].addEventListener('click', (event) => {

                event.preventDefault();
                const panelId = event.currentTarget.dataset.panelId;
                const panel = document.getElementById(`panel-${panelId}`);
                const link = document.getElementById(`link-${panelId}`);
                const linkActive = link.classList.contains('active-menu') ? true : false;

                if (linkActive) {
                    panel.classList.remove('active');
                    link.classList.remove('active-menu');
                    projectWrapper.classList.remove('active');
                }
                else {
                    removeMenus();
                    panel.classList.add('active');
                    link.classList.add('active-menu');
                    projectWrapper.classList.add('active');
                }

            });
        }

        bindEvent(subPanelOverlay, "click", function() {
            removeMenus();
            projectWrapper.classList.remove('active');
        });
    }

    function removeMenus() {
        panels.forEach(item => {
            item.classList.remove('active');
        })
        panelTriggers.forEach(item => {
            item.classList.remove('active-menu');
        })
        subPanels.forEach(item => {
            item.classList.remove('active');
        })
        subPanelTriggers.forEach(item => {
            item.classList.remove('active');
        })
    }


    // tile swiper
    const tileSwiper = new Swiper('.tile-swiper', {
        loop: false,
        //spaceBetween: 16,
        slidesPerView: 'auto',

        // breakpoints: {
        //     1280: {
        //         spaceBetween: 32,
        //     },
        // },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    
    var nextBtn = document.querySelector('.swiper-button-next'),
        prevBtn = document.querySelector('.swiper-button-prev');
    
    if (nextBtn) {
        var viewportWidth;

        var setViewportWidth = function () {
            viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        }


        if (tileSwiper && tileSwiper.slides) {
            var swiper__slidecount = tileSwiper.slides.length;

            var logWidth = function () {
                if (viewportWidth > 1024) {
                    if (swiper__slidecount <= 3) {
                        prevBtn.classList.add('hide');
                        nextBtn.classList.add('hide');
                    }
                } else {
                    prevBtn.classList.remove('hide');
                    nextBtn.classList.remove('hide');
                }
            }

        setViewportWidth();
        logWidth();

        window.addEventListener('resize', function () {
            setViewportWidth();
            logWidth();
        }, false);
        }
    }

    /////

    // related swiper
    const relatedSwiper = new Swiper('.related-swiper', {
        loop: false,
        spaceBetween: 16,
        slidesPerView: 'auto',

        breakpoints: {
            1280: {
                spaceBetween: 32,
            },
        },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

     // gallery swiper
    const gallerySwiper = new Swiper('.gallery-swiper-block', {
        loop: false,
        spaceBetween: 16,
        slidesPerView: 'auto',

        breakpoints: {
            1280: {
                spaceBetween: 32,
            },
        },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // Tabs
    const sectionTabs = document.querySelector('.section-tabs');

    if (sectionTabs) {

        function TabsNavigation() {

            var bindAll = function() {
                var menuElements = document.querySelectorAll('[data-tab]');

                for(var i = 0; i < menuElements.length ; i++) {
                    menuElements[i].addEventListener('click', change, false);
                }
            }

            var clear = function() {
                var menuElements = document.querySelectorAll('[data-tab]');

                for(var i = 0; i < menuElements.length ; i++) {
                    menuElements[i].classList.remove('active');
                    var id = menuElements[i].getAttribute('data-tab');
                    document.getElementById(id).classList.remove('active');
                }
            }

            var change = function(e) {
                clear();
                e.target.classList.add('active');
                var id = e.currentTarget.getAttribute('data-tab');
                document.getElementById(id).classList.add('active');
            }

          bindAll();
        }

        function TabsSubNavigation() {

            var subTabsAcc = document.querySelectorAll('.section-tabs__sub-list');

            for (var ix = 0; ix < subTabsAcc.length; ix++) {
                var menuElements =  subTabsAcc[ix].querySelectorAll('[data-tab-sub]')

                var bindAll = function () {
                    for (var i = 0; i < menuElements.length; i++) {
                        menuElements[i].addEventListener('click', change, false);
                    }
                }

                var clear = function (el) {
                    let w = el.querySelectorAll('[data-tab-sub]')
                    for (var i = 0; i < w.length; i++) {
                        w[i].classList.remove('active');
                        var id = w[i].getAttribute('data-tab-sub');
                        document.getElementById(id).classList.remove('active');
                    }
                }

                var change = function (e) {
                    if(e.target.parentElement) {
                        clear(e.target.parentElement.parentElement);
                    }
                    e.target.classList.add('active');
                    var id = e.currentTarget.getAttribute('data-tab-sub');
                    document.getElementById(id).classList.add('active');
                }

                bindAll();
            }
        }

        TabsSubNavigation();
        TabsNavigation();
    }

     // gallery swiper
    const initSwiper = document.querySelector('.gallery');
    
    if (initSwiper) {
        bindEvent(initSwiper, "click", function() {
           
            const gallerySwiperTabs = new Swiper('.gallery-swiper', {
                spaceBetween: 16,
                slidesPerView: 'auto',
                centeredSlides: true,
                grabCursor: true,

                pagination: {
                  el: ".swiper-pagination",
                  clickable: true,
                },
            });

        });
    }

    const nav = document.querySelector('#stickyMenu');

    if(nav){

        const navTop = nav.offsetTop;

        function stickyNavigation() {

          if (window.scrollY >= navTop) {
            // nav offsetHeight = height of nav
            //document.body.style.paddingTop = nav.offsetHeight + 'px';
            document.body.classList.add('fixed-nav');
          } else {
            document.body.style.paddingTop = 0;
            document.body.classList.remove('fixed-nav');
          }
        }

        window.addEventListener('scroll', stickyNavigation);
    }

    // Accordions
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = this.closest('[data-accordion-link]');
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})
